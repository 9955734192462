import React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import { LayoutWhite } from "@layout"
import { HeroLegal2022Q3 } from "@components/hero"
import SEO from "@components/seo"
import "@styles/legal.scss"

const PrivacyPolicy = () => {
  return (
    <LayoutWhite
      backgroundImage="animated"
      hero={
        <HeroLegal2022Q3
          title="Privacy Policy"
          subtitle="Effective Date: April 1, 2024"
        />
      }
    >
      <SEO title="Privacy Policy" />
      <Container className="policy-container body-1">
        <p>
          ATM.com, Inc., dba ATM.com® (referred to herein as “ATM”, “ATM.com”,
          “we”, “us” and “our”) knows that you care about how your personal
          information is used and shared.
        </p>
        <p>
          We want to provide you with answers to your questions and concerns
          regarding how your personal information is used. You may direct any
          privacy-related questions to{" "}
          <a href="mailto:support@atm.com">support@ATM.com</a>.
        </p>
        <p>
          This Privacy Policy describes ATM.com's practices regarding the
          collection, use, and disclosure of the information we collect from and
          about you when you use ATM.com's website (
          <Link to="/">https://www.atm.com</Link>, or the “Website”), mobile
          application (“ATM.com” or the “Application” or the “App”), or
          associated products and services (collectively, with the Website and
          ATM.com, the “Services”). By accessing or otherwise using the
          Services, you consent to the collection, use, and disclosure of
          information as described in this Privacy Policy.
        </p>
        <p className="font-weight-bold">
          This Privacy Policy contains the following sections:
        </p>
        <ul className="list-unstyled">
          <li>The Information We Collect</li>
          <li>Cookies and Similar Technologies</li>
          <li>How We Use Your Information</li>
          <li>Online Analytics and Advertising</li>
          <li>How We Share and Disclose Your Information Your Choices</li>
          <li>Third Party Website Links and Features</li>
          <li>Children's Privacy</li>
          <li>Your Rights</li>
          <li>How Long We Store Your Information</li>
          <li>Changes to Our Privacy Policy</li>
          <li>How You Can Access and Update Your Information</li>
          <li>Data Security</li>
          <li>How We Protect Your Information</li>
          <li>California Privacy Rights</li>
          <li>Use of the Services from Outside the United States</li>
          <li>ATM.com Contact Information</li>
        </ul>
        <h2>THE INFORMATION WE COLLECT</h2>
        <p>
          <strong>Information You Provide to Us</strong>
          <br />
          ATM.com collects information from you when you do certain things, such
          as:
        </p>
        <ul>
          <li>
            Register for, administer, or sign into your account with ATM.com
            ("ATM.com Account")
          </li>
          <li>Make a purchase</li>
          <li>Sign up to receive SMS (text message) notifications</li>
          <li>Download or use the ATM.com Application</li>
          <li>
            Use or otherwise interact with the Services directly or through
            social media
          </li>
          <li>Submit requests or questions to us via forms or email</li>
          <li>
            Communicate with us or have other dealings with us, including
            requests for customer support or technical assistance
          </li>
          <li>Submit uploads or posts to the Services</li>
        </ul>
        <p>
          <strong>Our “Data Messenger” Service</strong>
          <br />
          ATM.com helps subscribers earn money (“Rewards”) with our Services.
          One way subscribers earn money with our Services is by matching with a
          brand and opting in to receive messages from brands through the
          ATM.com application (“Data Messenger”). This is how our Data Messenger
          works. First, we compile information from you regarding your unique
          personal interests. Then, we link this information to a proprietary
          token that doesn't identify you to the outside world. We do not share
          any of your personal information with brands in this process; your
          personal information remains inside our secure vault (see below DATA
          SECURITY; HOW WE PROTECT YOUR INFORMATION). You may decide to opt-in
          to receiving in-app messages, notifications, and emails from any brand
          you may select through the Data Messenger.
        </p>
        <p>
          <strong>Types of Information</strong>
          <br />
          The types of information we collect will depend upon the Services you
          use, how you use them, and what you choose to provide. The types of
          data we collect directly from you may include:
        </p>
        <ul>
          <li>
            Name, address, telephone number, email address, date of birth, SSN,
            and additional information collected for purposes of identity
            verification
          </li>
          <li>
            Optional information, including demographic information, or
            information that you elect to associate with your account (your
            “Profile Information”)
          </li>
          <li>Log-in details and password, if you create an ATM.com account</li>
          <li>Any email requests or questions you submit to us</li>
          <li>
            User content including but not limited to emails, communications,
            and other information for publication or distribution to third
            parties.
          </li>
          <li>
            If you choose to link a personal bank account ("Bank Account") or
            brokerage account to your ATM.com user account within the Services,
            we will also request information from you such as your login
            credentials for those accounts and other financial data and
            documents that we receive from the financial institution.
          </li>
        </ul>
        <p>
          For users who elect an ATM Instant Cash Advance, we may also collect
          the following types of bank account information from your linked bank:
        </p>
        <ul>
          <li>Balance information</li>
          <li>Transaction dates</li>
          <li>Merchant names</li>
          <li>Transaction types</li>
        </ul>
        <p>
          <strong>Information We Automatically Collect</strong>
          <br />
          When you use the Services, we may automatically collect certain
          information as described in this Section. As discussed further below,
          we and our service providers (which are third party companies that
          work on our behalf to provide and enhance the Services) use a variety
          of technologies, including cookies and similar tools, to assist in
          collecting this information.
        </p>
        <p>
          <strong>Log Files</strong>
          <br />
          When you use the Services, our servers automatically record certain
          information in server logs. These server logs may include information
          such as your web request, Internet Protocol (“IP”) address, browser
          type and settings, referring / exit pages and URLs, number of clicks
          and how you interact with the Services, metadata associated with
          uploaded Content, domain names, landing pages, pages viewed or
          moused-over, mobile carrier, date and time stamp information, and
          other such information.
        </p>
        <p>
          <strong>Device Identifiers</strong>
          <br />
          When you access the Services using a computer or mobile device, we may
          collect specific device information, including your MAC address and
          other unique device identifiers. We also collect information such as
          the type of device you are using, its operating system, and mobile
          network information, which may include your mobile phone number. We
          may associate this device identifier with your ATM.com Account and
          will use data associated with your device identifier to customize our
          Services to your device and to analyze any device-related issues. We
          may also gather information from your device to assist us with
          cross-device tracking, as described further below.
        </p>
        <p>
          <strong>Location Information</strong>
          <br />
          We may collect different types of information about your location,
          including general information (e.g., IP address, zip code) and more
          specific information (e.g., GPS-based functionality on mobile devices
          used to access the Services), and may use that information to
          customize the Services with location-based information, advertising,
          and features. For example, if your IP address indicates an origin in
          Los Angeles, the Services may be customized with Los Angeles- specific
          information and advertisements. If you access the Services through a
          mobile device and you do not want your device to provide us with
          location-tracking information, you can disable the GPS or other
          location-tracking functions on your device, provided your device
          allows you to do this. See your device manufacturer's instructions for
          further details. Even if you disable this, we may make certain
          assumptions about your location from your IP Address, or other
          information related to your use of the Services.
        </p>
        <p>
          In the App, if you voluntarily select to Enable Location Rewards, you
          will be given a choice to allow us to access your location one time or
          while using the App or not at all. If you authorize our access to your
          location data, we will share that data with third-party marketing
          partners, such as InMarket, in order to provide the services and
          features of Location Rewards.
        </p>
        <p>
          <strong>Information We Collect from Third-Party Integrations</strong>
          <br />
          If you choose to use third-party integrations through the Services,
          such as our transaction partners, such providers may allow us and our
          service providers to have access to and store additional information
          about your interaction with those services and platforms as it relates
          to use of the Services. As part of its Services to you, ATM.com
          monitors and analyzes all of your shared data.
        </p>
        <p>
          <strong>
            Information We Collect from Affiliates and Non-Affiliated Third
            Parties
          </strong>
          <br />
          ATM.com may receive additional information about you, such as
          demographic information, from third parties, such as business
          partners, marketers, researchers, analysts, and other parties that we
          may use to supplement the information that we collect directly from
          you.
        </p>
        <p>
          When you link your Bank Account to your ATM.com Account, ATM.com may
          also receive information about you and your Bank Account from the
          financial institution holding your Bank Account as necessary to
          provide the Services to you.
        </p>
        <p>
          <strong>Collection of Information Across Devices</strong>
          <br />
          Sometimes, we may use the information we collect -- for instance,
          usernames, IP addresses and unique mobile device identifiers - to
          locate or try to locate the same unique users across multiple browsers
          or devices (such as smartphones or tablets), or work with service
          providers that do this, in order to provide you with a seamless
          experience across devices.
        </p>
        <h2>COOKIES AND SIMILAR TECHNOLOGIES</h2>
        <p>
          To collect the information in the “Information We Automatically
          Collect” section above, we and our service providers use Internet
          server logs, cookies, tags, SDKs, tracking pixels, and other similar
          tracking technologies. A web server log is a file where website
          activity is stored. An SDK is a section of code that we embed in our
          applications and software to allow third parties to collect
          information about how users interact with the Services. A cookie is a
          small text file that is placed on your computer or mobile device when
          you visit a site, that enables us to: (i) recognize your computer;
          (ii) store your preferences and settings; (iii) understand which web
          pages of the Services you have visited; (iv), enhance your user
          experience by delivering and measuring the effectiveness of content
          and advertising tailored to your interests; (v) perform analytics; and
          (vi) assist with security and administrative functions. Tracking
          pixels (sometimes referred to as web beacons or clear GIFs) are tiny
          electronic tags with a unique identifier embedded in websites, online
          ads and/or email, and that are designed to provide usage information
          like ad impressions or clicks, email open rates, measure popularity of
          the Services and associated advertising, and to access user cookies.
          As we adopt additional technologies, we may also gather information
          through other methods.
        </p>
        <p>
          Most browsers automatically accept cookies, but you can modify your
          browser setting to decline cookies by visiting the Help portion of
          your browser toolbar (e.g., Internet Explorer; Google Chrome; Mozilla
          Firefox; or Apple Safari). If you choose to decline cookies, please
          note that you may not be able to sign in, customize, or use some of
          the interactive features of the Services. Flash cookies operate
          differently than browser cookies, and cookie management tools
          available in a web browser will not remove Flash cookies. To learn
          more about how to manage Flash cookies, you can visit the{" "}
          <a href="https://www.adobe.com/privacy.html">Adobe website</a> and
          make changes at the{" "}
          <a href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager02.html">
            Global Privacy
          </a>{" "}
          Settings Panel.
        </p>
        <h2>HOW WE USE YOUR INFORMATION</h2>
        <p>
          <strong>Use</strong>
          <br />
          We use your information (including any information that we collect, as
          described in this Privacy Policy) for various purposes depending on
          the types of information we have collected from and about you and the
          specific ATM.com Services you use, including to:
        </p>
        <ul>
          <li>
            Provide you the opportunity to earn money with our Data Messenger
            service
          </li>
          <li>Provide the services and customer support you have requested</li>
          <li>
            Provide the highest earning opportunities with our Paid Survey
            service
          </li>
          <li>
            Provide earning opportunities tailored specifically to your location
            using our Location Rewards service
          </li>
          <li>
            Process transactions and send notices about your transactions or
            your network activity
          </li>
          <li>Compare information for accuracy and verification purposes</li>
          <li>
            Respond to your request for information and provide you with more
            effective and efficient customer service
          </li>
          <li>
            Provide you with product and service updates that we are offering
          </li>
          <li>
            Provide you with service notifications via email and within the
            Services based on your notification selections
          </li>
          <li>Customize the advertising and content you see</li>
          <li>
            Synthesize and derive insights from your use of the Services to help
            us better understand your interests and needs, and improve the
            services
          </li>
          <li>Protect our Services and our users</li>
          <li>Enforce the legal terms that govern your use of the Services</li>
        </ul>
        <p>
          If you have elected an ATM Instant Cash Advance, we may also use your
          information for our computer analysis to determine if your request for
          an ATM Instant Cash Advance will be approved.
        </p>
        <p>
          <strong>User Content</strong>
          <br />
          The Services may permit you to submit content, send emails and other
          communications, and provide other information for publication or
          distribution to third parties (collectively, "User Content"). ATM.com
          may view and share your User Content for any reason, including: (i) to
          maintain, provide and improve the Service; (ii) to resolve a support
          request from you; (iii) if we have a good faith belief, or have
          received a complaint alleging, that such Content is in violation of
          our Terms of Service; (iv) as reasonably necessary to allow ATM.com to
          comply with or avoid the violation of applicable law or regulation; or
          (v) to comply with a valid legal subpoena, request, or other lawful
          process. We may also analyze your User Content in order to better
          understand the manner in which our Service is being used.
        </p>
        <p>
          <strong>Combined Information</strong>
          <br />
          For the purposes discussed in this Policy, we may combine the
          information that we collect through the Services with information that
          we receive from other sources, both online and offline, and use such
          combined information in accordance with this Policy.
        </p>
        <p>
          <strong>De-Identified and Aggregated Information</strong>
          <br />
          We may de-identify and aggregate information collected through the
          Services from our subscribers so that such information can no longer
          be linked to you or your device. We may (i) monetize this information
          collected as survey data and share the results in part with our
          subscribers, and (ii) use it for purposes of market or scientific
          research.
        </p>
        <h2>ONLINE ANALYTICS AND ADVERTISING</h2>
        <p>
          <strong>Analytics</strong>
          <br />
          We use third-party web analytics services on our Services to collect
          and analyze some of the information discussed above, and to engage in
          auditing, research or reporting. The information (including your IP
          address) collected by various analytics technologies described in the
          “Cookies and Similar Technologies” section will be disclosed to or
          collected directly by these service providers, who use the information
          to evaluate your use of the Services, including by noting the
          third-party website from which you arrive, analyzing usage trends
          across ATM.com websites and mobile devices, assisting with fraud
          prevention, and providing certain features to you.
        </p>
        <p>
          If you receive email from us, we may use certain analytics tools to
          capture data, such as when you open our message or click on any links
          or banners our email contains. This data allows us to gauge the
          effectiveness of our communications and marketing campaigns.
        </p>
        <p>
          <strong>Online Advertising</strong>
          <br />
          Third parties or affiliates may administer ATM.com banner advertising
          programs and other online marketing on non-ATM.com websites and
          services. To do so, these parties may set and access first-party
          cookies delivered from an ATM.com domain, or they may use third-party
          cookies or other tracking mechanisms. For example, a third-party
          provider may use the fact that you visited the ATM website to target
          online ads (retargeted ads) for ATM.com services to you on non-ATM.com
          websites or across mobile apps. Or a third- party ad network might
          collect information on the Services and other websites to develop a
          profile of your interests and target advertisements to you based on
          your online behavior (retargeted ads). These parties that use these
          technologies may offer you a way to opt out of ad targeting as
          described below. You may receive retargeted ads on your computer
          through a web browser.
        </p>
        <p>
          If you are interested in more information about tailored browser
          advertising and how you can generally control cookies from being put
          on your computer to deliver tailored advertising, you may visit the{" "}
          <a href="https://optout.networkadvertising.org/">
            Network Advertising Initiative's Consumer Opt-Out Link
          </a>
          , the{" "}
          <a href="https://optout.aboutads.info/">
            Digital Advertising Alliance's Consumer Opt-Out link
          </a>
          , or{" "}
          <a href="https://preferences-mgr.truste.com/">
            TRUSTe's Advertising Choices Page
          </a>{" "}
          to opt-out of receiving tailored advertising from companies that
          participate in those programs. To opt out of Google Analytics for
          display advertising or customize Google display network ads, you can
          visit the{" "}
          <a href="https://adssettings.google.com/">Google Ad Settings page</a>.
          Please note that we do not control any of the above opt-out links or
          whether any particular company chooses to participate in these opt-out
          programs. We are not responsible for any choices you make using these
          mechanisms or the continued availability or accuracy of these
          mechanisms.
        </p>
        <p>
          <strong>California Do-Not-Track Disclosure Requirements.</strong>
          <br />
          We are committed to providing you with meaningful choices about the
          information collected on our Services for third-party purposes, and
          that is why we provide above the Network Advertising Initiatives
          Consumer Opt-out link, Digital Advertising Alliances Consumer Opt-Out
          Link, TRUSTe's Advertising Choices page, the Google opt-out link.
          However, we do not recognize or respond to browser-initiated Do Not
          Track signals, as the Internet industry is currently still working on
          Do Not Track standards, implementations and solutions.
        </p>
        <h2>DO WE SHARE YOUR INFORMATION?</h2>
        <p>
          <strong>Affiliates and Subsidiaries</strong>
          <br />
          We may share your information with our affiliated entities such as
          subsidiaries. Affiliated entities are entities that we legally control
          (by voting rights) or that control us.
        </p>
        <p>
          <strong>Service Providers</strong>
          <br />
          We may provide access to or share your information with select third
          parties who perform services on our behalf. These third parties
          provide a variety of services to us, including without limitation,
          billing, customer service, data storage, security, fraud prevention,
          payment processing through the Automated Clearing House Network, and
          legal services.
        </p>
        <p>
          <strong>Legal Process</strong>
          <br />
          We may disclose your information in response to any subpoenas, court
          orders, or other legal process we receive, or to establish or exercise
          our legal rights or to defend against legal claims.
        </p>
        <p>
          <strong>Investigations</strong>
          <br />
          We may disclose your information when we believe in good faith that
          such disclosure is appropriate in order to investigate, prevent, or
          take action regarding possible illegal activities, suspected fraud,
          situations involving potential threats to the physical safety of any
          person, violations of this Privacy Policy or the Terms of Service for
          the Services you accessed, and/or to protect the rights and property
          of ATM.com, our employees, users, and the public. This may involve the
          sharing of your information with law enforcement, government agencies,
          courts, and/or other organizations.
        </p>
        <p>
          <strong>Third-Party Integrations</strong>
          <br />
          When you initiate a connection with a third-party integration through
          the Services, we will share information about you that is required to
          enable your use of the third-party integration through the Services.
          These third parties may use the location and device data, as well as
          information you voluntarily submit to them, for targeted advertising,
          measurement and analytics.
        </p>
        <p>
          <strong>Business Transfers</strong>
          <br />
          If the ownership of all or substantially all of our business changes,
          we may transfer your information to the new owner so that the Services
          can continue to operate. In such case, your information would remain
          subject to the promises and commitments contained in this Privacy
          Policy until such time as this Privacy Policy is updated or amended by
          the acquiring party upon notice to you. If such transfer is subject to
          additional mandatory restrictions under applicable laws, ATM.com will
          comply with such restrictions.
        </p>
        <p>
          <strong>De-Identified and Aggregated Information</strong>
          <br />
          As stated above, we may share and monetize De-Identified and
          Aggregated Information collected as survey data from our subscribers.
        </p>
        <p>
          <strong>Consent</strong>
          <br />
          We may also disclose your information to third parties with your prior
          consent.
        </p>
        <h2>YOUR CHOICES</h2>
        <p>
          We provide you with a number of choices with respect to the
          information we collect and use as discussed throughout this Privacy
          Policy. For example, you may instruct us not to use your contact
          information to contact you by email, postal mail or phone regarding
          products, services, promotions and special events that might appeal to
          your interests by contacting us. In commercial email messages, you can
          also opt out by following the instructions located at the bottom of
          such emails. Please note that, regardless of your request, we may
          still use and share certain information as permitted by this Privacy
          Policy or as required by applicable law. For example, you may not opt
          out of certain operational or service-related emails, such as those
          reflecting our relationship or transactions with you.
        </p>
        <h2>THIRD PARTY LINKS AND SERVICES</h2>
        <p>
          The Services contain links to third-party apps or websites or
          services, and also contain third-party integrations. If you choose to
          use these, you may disclose your information not just to those third
          parties, but also to their users and the public more generally
          depending on how their services function. Because these third-party
          websites and services are not operated by ATM.com, ATM.com is not
          responsible for the content or practices of those websites or
          services. The collection, use, and disclosure of your personal and
          other information will be subject to the privacy policies of the
          third- party websites or services, and not this Policy. We urge you to
          read the privacy and security policies of these third parties.
        </p>
        <h2>ELIGIBILITY</h2>
        <p>
          The Services are not intended for individuals under the age of 18. We
          do not knowingly solicit or collect information from individuals under
          the age of 18.
        </p>
        <h2>YOUR RIGHTS</h2>
        <p>
          If you want to learn more about the information collected through the
          Services, or if you would like to access or rectify your information
          and/or request deletion of information we collect about you, or
          restrict or object to the processing of your information, please
          contact us using the contact information below.
        </p>
        <p>
          Where you have provided consent, you may withdraw your consent at any
          time, without affecting the lawfulness of the processing that was
          carried out prior to withdrawing your consent.
        </p>
        <h2>HOW LONG WE STORE YOUR INFORMATION</h2>
        <p>
          We will retain your information for the period necessary to fulfill
          the purposes outlined in this Policy unless a longer retention period
          is required or permitted by law.
        </p>
        <h2>CHANGES TO OUR PRIVACY POLICY</h2>
        <p>
          We reserve the right to amend this Policy at any time to reflect
          changes in the law, our data collection and use practices, the
          features of our Services, or advances in technology. We will make the
          revised Policy accessible through the Services, so you should review
          the Policy periodically. If we make a material change to the Policy,
          you will be provided with appropriate notice and we will seek your
          consent to the updated Policy in accordance with legal requirements.
        </p>
        <h2>HOW YOU CAN ACCESS AND UPDATE YOUR INFORMATION</h2>
        <p>
          To access, review, update and/or delete certain user profile
          information, you may update and/or delete certain user profile
          information by logging into the Website and updating your ATM.com
          Account. Please remember, however, if we have already disclosed some
          of this user profile information to third parties, we cannot force the
          deletion or modification of any such user profile information by the
          parties to whom we have made those disclosures.
        </p>
        <h2>DATA SECURITY; HOW WE PROTECT YOUR INFORMATION</h2>
        <p>
          We will implement reasonable and appropriate security procedures
          consistent with prevailing industry standards to protect information
          from unauthorized access by physical and electronic intrusion.
          Unfortunately, no data transmission over the Internet or method of
          data storage can be guaranteed 100% secure. Therefore, while we strive
          to protect your information by following generally accepted industry
          standards, we cannot ensure or warrant the absolute security of any
          information you transmit to us or archive at this site. If you have
          any questions about how we protect your information, you can contact
          us at <a href="mailto:support@atm.com">support@ATM.com</a>.
        </p>
        <h2>CALIFORNIA PRIVACY RIGHTS</h2>
        <p>
          California law permits users who are California residents to request
          and obtain from us once a year, free of charge, a list of the third
          parties to whom we have disclosed their personal information (if any)
          for their direct marketing purposes in the prior calendar year, as
          well as the type of personal information disclosed to those parties.
          If you are a California resident and would like to request this
          information, please submit your request in an email to{" "}
          <a href="mailto:support@atm.com">support@ATM.com</a>.
        </p>
        <p>
          Additionally, California law permits residents under the age of 18 who
          are registered users with us to request and obtain removal of content
          or information they have publicly posted on our Services. To make such
          a request, please send an email with a detailed description of the
          specific content or information to{" "}
          <a href="mailto:support@atm.com">support@ATM.com</a>. Please be aware
          that such a request does not ensure complete or comprehensive removal
          of the content or information you have posted and that there may be
          circumstances in which the law does not require or allow removal even
          if requested.
        </p>
        <h2>USE OF THE SERVICES FROM OUTSIDE THE UNITED STATES</h2>
        <p>
          The Services are provided, supported, and hosted in the United States
          and are governed by United States law and the laws of the applicable
          states within the United States. If you are using the Services from
          outside the United States, please be aware that your information may
          be transferred to, stored and processed in the United States where our
          servers are located and our databases are operated. The data
          protection and other laws of the United States might not be as
          comprehensive as those in your country. By using the Services, you
          consent to your information being transferred to our facilities and to
          the facilities of those third parties with whom we share it as
          described in our Privacy Policy.
        </p>
        <h2>CONTACT US</h2>
        <p>
          If you wish to contact us or have any questions about or complaints in
          relation to this Privacy Policy, please contact us at{" "}
          <a href="mailto:support@atm.com">support@ATM.com</a>, or ATM.com,
          Inc., 4600 Campus Drive, Suite 107, Newport Beach, CA 92660.
        </p>
        <a
          className="anchors"
          id="California-Privacy-Rights"
          name="California-Privacy-Rights"
          title="California Privacy Rights"
        ></a>
        <h2 className="text-center my-5">
          PRIVACY NOTICE FOR CALIFORNIA RESIDENTS
          <br />
          Effective Date: September 25, 2020
          <br />
          Last Reviewed on April 1, 2024
        </h2>
        <p>
          This Privacy Notice for California Residents supplements the
          information contained in the ATM.com{" "}
          <Link to="/privacy-policy">Privacy Policy</Link> and applies solely to
          all visitors, users, and others who reside in the State of California
          ("consumers" or "you"). ATM.com, Inc. (“we”, “our” or “us”) adopts
          this notice to comply with the California Consumer Privacy Act of 2018
          (“CCPA”) and any terms defined in the CCPA have the same meaning when
          used in this notice.
        </p>
        <h2>INFORMATION WE COLLECT</h2>
        <p>
          Our website collects information that identifies, relates to,
          describes, references, is capable of being associated with, or could
          reasonably be linked, directly or indirectly, with a particular
          consumer or device ("Personal Information").
        </p>
        <p>
          In particular, we indicate below the categories of Personal
          Information collected from California residents within the last twelve
          (12) months.
        </p>
        <p>CATEGORY A: Identifiers</p>
        <p>
          Examples: A real name, Internet Protocol address, email address, or
          other similar identifiers.
        </p>
        <p>Collected: YES</p>
        <p>
          CATEGORY B: Personal information categories listed in the California
          Customer Records statute (Cal. Civ. Code § 1798.80(e)).
        </p>
        <p>
          Examples: A name, signature, Social Security number, physical
          characteristics or description, address, telephone number, passport
          number, driver's license or state identification card number,
          insurance policy number, education, employment, employment history,
          bank account number, credit card number, debit card number, or any
          other financial information, medical information, or health insurance
          information. Some Personal Information included in this category may
          overlap with other categories.
        </p>
        <p>Collected: YES</p>
        <p>
          CATEGORY C: Protected classification characteristics under California
          or federal law.
        </p>
        <p>
          Examples: Age (40 years or older), race, color, ancestry, national
          origin, citizenship, religion or creed, marital status, medical
          condition, physical or mental disability, sex (including gender,
          gender identity, gender expression, pregnancy or childbirth and
          related medical conditions), sexual orientation, veteran or military
          status, genetic information (including familial genetic information).
        </p>
        <p>Collected: YES</p>
        <p>CATEGORY D: Commercial information.</p>
        <p>
          Examples: Records of personal property, products or services
          purchased, obtained, or considered, or other purchasing or consuming
          histories or tendencies.
        </p>
        <p>Collected: YES</p>
        <p>CATEGORY E: Biometric information.</p>
        <p>
          Examples: Genetic, physiological, behavioral, and biological
          characteristics, or activity patterns used to extract a template or
          other identifier or identifying information, such as, fingerprints,
          faceprints, and voiceprints, iris or retina scans, keystroke, gait, or
          other physical patterns, and sleep, health, or exercise data.
        </p>
        <p>Collected: YES</p>
        <p>CATEGORY F: Internet or other similar network activity.</p>
        <p>
          Examples: Browsing history, search history, information on a
          consumer's interaction with a website, application, or advertisement.
        </p>
        <p>Collected: YES</p>
        <p>CATEGORY G: Geolocation data.</p>
        <p>Examples: Physical location or movements.</p>
        <p>Collected: YES</p>
        <p>CATEGORY H: Sensory data.</p>
        <p>
          Examples: Audio, electronic, visual, thermal, olfactory, or similar
          information.
        </p>
        <p>Collected: YES</p>
        <p>
          CATEGORY I: Professional or employment-related information. Examples:
          Current or past job history or performance evaluations.
        </p>
        <p>Collected: YES</p>
        <p>
          CATEGORY J: Non-public education information (per the Family
          Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R.
          Part 99)).
        </p>
        <p>
          Examples: Education records directly related to a student maintained
          by an educational institution or party acting on its behalf, such as
          grades, transcripts, class lists, student schedules, student
          identification codes, student financial information, or student
          disciplinary records.
        </p>
        <p>Collected: YES</p>
        <p>CATEGORY K: Inferences drawn from other Personal Information.</p>
        <p>
          Examples: Profile reflecting a person's preferences, characteristics,
          psychological trends, predispositions, behavior, attitudes,
          intelligence, abilities, and aptitudes.
        </p>
        <p>Collected: YES</p>
        <p>Personal information does not include:</p>
        <ul>
          <li>Publicly available information from government records.</li>
          <li>Deidentified or aggregated consumer information.</li>
          <li>
            Information excluded from the CCPA's scope, like: Health or medical
            information covered by the Health Insurance Portability and
            Accountability Act of 1996 (HIPAA) and the California
            Confidentiality of Medical Information Act (CMIA) or clinical trial
            data;
          </li>
          <li>
            Personal Information covered by certain sector-specific privacy
            laws, including the Fair Credit Reporting Act (FRCA), the
            Gramm-Leach-Bliley Act (GLBA) or California Financial Information
            Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.
          </li>
        </ul>
        <p>
          We collect the categories of Personal Information listed above from
          the following categories of sources:
        </p>
        <ul>
          <li>
            Directly from you (for example, from forms you complete or products
            and services you purchase).
          </li>
          <li>
            Indirectly from you (for example, from observing your actions on our
            website).
          </li>
          <li>Otherwise as described in our Privacy Policy.</li>
        </ul>
        <h2>USE OF PERSONAL INFORMATION</h2>
        <p>
          We may use, or disclose the Personal Information we collect for one or
          more of the following business purposes (a business purpose is defined
          as “the use of Personal Information for the business's or a service
          provider's operational purposes, or other notified purposes, provided
          that the use of Personal Information shall be reasonably necessary and
          proportionate to achieve the operational purpose for which the
          Personal Information was collected or processed or for another
          operational purpose that is compatible with the context in which the
          Personal Information was collected”):
        </p>
        <ul>
          <li>
            To fulfill or meet the reason you provided the information. For
            example, if you share your name and contact information to request a
            price quote or ask a question about our products or services, we
            will use that Personal Information to respond to your inquiry. If
            you provide your Personal Information to purchase a product or
            service, we will use that information to process your payment and
            facilitate delivery. We may also save your information to facilitate
            new product orders or process returns.
          </li>
          <li>
            To provide, support, personalize, and develop our website, products,
            and services.
          </li>
          <li>
            To create, maintain, customize, and secure your account with us.
          </li>
          <li>
            To process your requests, purchases, transactions, and payments and
            prevent transactional fraud.
          </li>
          <li>
            To provide you with support and to respond to your inquiries,
            including to investigate and address your concerns and monitor and
            improve our responses.
          </li>
          <li>
            To personalize your website experience and to deliver content and
            product and service offerings relevant to your interests, including
            targeted offers and retargeted ads through our website, third-party
            sites, and via email or text message (with your consent, where
            required by law).
          </li>
          <li>
            To help maintain the safety, security, and integrity of our website,
            products and services, databases and other technology assets, and
            business.
          </li>
          <li>
            For testing, research, analysis, and product development, including
            to develop and improve our website, products, and services.
          </li>
          <li>
            To respond to law enforcement requests and as required by applicable
            law, court order, or governmental regulations.
          </li>
          <li>
            As described to you when collecting your Personal Information or as
            otherwise set forth in the CCPA.
          </li>
          <li>
            To evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which Personal
            Information held by us about our website users is among the assets
            transferred.
          </li>
          <li>
            Otherwise as described in our Privacy Policy, including without
            limitation, engagement in retargeted ads via ad networks.
          </li>
        </ul>
        <p>
          We will not collect additional categories of Personal Information or
          use the Personal Information we collected for materially different,
          unrelated, or incompatible purposes without providing you notice.
        </p>
        <h2>SHARING AND DISCLOSURE OF PERSONAL INFORMATION – THIRD PARTIES</h2>
        <p>
          We may share your personal information as indicated above in our
          Privacy Policy (see above DO WE SHARE YOUR INFORMATION?).
        </p>
        <h2>
          DISCLOSURES OF PERSONAL INFORMATION FOR A BUSINESS PURPOSE -
          CATEGORIES
        </h2>
        <p>
          In the preceding twelve (12) months, Company has disclosed the
          following categories of Personal Information for a business purpose:
        </p>
        <ul>
          <li>Category A: Identifiers.</li>
          <li>
            Category B: California Customer Records Personal Information
            categories.
          </li>
          <li>
            Category C: Protected classification characteristics under
            California or federal law.
          </li>
          <li>Category D: Commercial information.</li>
          <li>Category E: Biometric information.</li>
          <li>Category F: Internet or other similar network activity.</li>
          <li>Category G: Geolocation data.</li>
          <li>Category H: Sensory data.</li>
          <li>Category I: Professional or employment-related information</li>
          <li>
            Category J: Non-public education information (per the Family
            Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34
            C.F.R. Part 99)).
          </li>
          <li>Category K: Inferences drawn from other Personal Information</li>
        </ul>
        <h2>SALES OF PERSONAL INFORMATION</h2>
        <p>
          In the preceding twelve (12) months, we have not sold Personal
          Information to data brokers, aggregators, or to other third parties
          with transactions involving the transfer of our lists for monetary
          compensation. We have engaged in retargeted ads via ad networks; to
          opt out, visit our Privacy Policy above (See ONLINE ANALYTICS AND
          ADVERTISING).
        </p>
        <h2>YOUR RIGHTS AND CHOICES</h2>
        <p>
          The CCPA provides consumers (California residents) with specific
          rights regarding their Personal Information. This section describes
          your CCPA rights and explains how to exercise those rights.
        </p>
        <p>Access to Specific Information and Data Portability Rights</p>
        <p>
          You have the right to request that we disclose certain information to
          you about our collection and use of your Personal Information over the
          past 12 months. Once we receive and confirm your verifiable consumer
          request (see EXERCISING ACCESS, DATA PORTABILITY, AND DELETION
          RIGHTS), we will disclose to you:
        </p>
        <ul>
          <li>
            The categories of Personal Information we collected about you.
          </li>
          <li>
            The categories of sources for the Personal Information we collected
            about you.
          </li>
          <li>
            Our business or commercial purpose for collecting or selling that
            Personal Information.
          </li>
          <li>
            The categories of third parties with whom we share that Personal
            Information.
          </li>
          <li>
            The specific pieces of Personal Information we collected about you
            (also called a data portability request).
          </li>
          <li>
            If we sold or disclosed your Personal Information for a business
            purpose, two separate lists disclosing:
            <ul>
              <li>
                sales, identifying the Personal Information categories that each
                category of recipient purchased; and
              </li>
              <li>
                disclosures for a business purpose, identifying the Personal
                Information categories that each category of recipient obtained.
              </li>
            </ul>
          </li>
        </ul>
        <h2>DELETION REQUEST RIGHTS</h2>
        <p>
          You have the right to request that we delete any of your Personal
          Information that we collected from you and retained, subject to
          certain exceptions. Once we receive and confirm your verifiable
          consumer request (see EXERCISING ACCESS, DATA PORTABILITY, AND
          DELETION RIGHTS below), we will delete (and direct our service
          providers to delete) your Personal Information from our records,
          unless an exception applies.
        </p>
        <p>
          We may deny your deletion request if retaining the information is
          necessary for us or our service provider(s) to:
        </p>
        <ul>
          <li>
            Complete the transaction for which we collected the Personal
            Information, provide a good or service that you requested, take
            actions reasonably anticipated within the context of our ongoing
            business relationship with you, or otherwise perform our contract
            with you.
          </li>
          <li>
            Detect security incidents, protect against malicious, deceptive,
            fraudulent, or illegal activity, or prosecute those responsible for
            such activities.
          </li>
          <li>
            Debug products to identify and repair errors that impair existing
            intended functionality.
          </li>
          <li>
            Exercise free speech, ensure the right of another consumer to
            exercise their free speech rights, or exercise another right
            provided for by law.
          </li>
          <li>
            Comply with the California Electronic Communications Privacy Act
            (Cal. Penal Code § 1546 et. seq.).
          </li>
          <li>
            Engage in public or peer-reviewed scientific, historical, or
            statistical research in the public interest that adheres to all
            other applicable ethics and privacy laws, when the information's
            deletion may likely render impossible or seriously impair the
            research's achievement, if you previously provided informed consent.
          </li>
          <li>
            Enable solely internal uses that are reasonably aligned with
            consumer expectations based on your relationship with us.
          </li>
          <li>Comply with a legal obligation.</li>
          <li>
            Make other internal and lawful uses of that information that are
            compatible with the context in which you provided it.
          </li>
        </ul>
        <h2>EXERCISING ACCESS, DATA PORTABILITY, AND DELETION RIGHTS</h2>
        <p>
          To exercise the access, data portability, and deletion rights
          described above, please submit a verifiable consumer request to us by:
        </p>
        <ul>
          <li>
            Emailing us with the contact information described below under
            CONTACT US.
            <ul>
              <li>
                Only you, or a person registered with the California Secretary
                of State that you authorize to act on your behalf, may make a
                verifiable consumer request related to your Personal
                Information. You may also make a verifiable consumer request on
                behalf of your minor child.
              </li>
              <li>
                You may only make a verifiable consumer request for access or
                data portability twice within a 12-month period. The verifiable
                consumer request must:
                <ul>
                  <li>
                    Provide sufficient information that allows us to reasonably
                    verify you are the person about whom we collected Personal
                    Information or an authorized representative.
                  </li>
                  <li>
                    Describe your request with sufficient detail that allows us
                    to properly understand, evaluate, and respond to it.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          We cannot respond to your request or provide you with Personal
          Information if we cannot verify your identity or authority to make the
          request and confirm the Personal Information relates to you.
        </p>
        <p>
          Making a verifiable consumer request does not require you to create an
          account with us. However, we do consider requests made through your
          password protected account sufficiently verified when the request
          relates to Personal Information associated with that specific account.
        </p>
        <p>
          We will only use Personal Information provided in a verifiable
          consumer request to verify the requestor's identity or authority to
          make the request.
        </p>
        <p>
          For instructions on exercising sale opt-out rights, see PERSONAL
          INFORMATION SALES OPT-OUT AND OPT-IN RIGHTS below.
        </p>
        <h2>RESPONSE TIMING AND FORMAT</h2>
        <p>
          We endeavor to respond to a verifiable consumer request within
          forty-five (45) days of its receipt. If we require more time (up to 90
          days), we will inform you of the reason and extension period in
          writing.
        </p>
        <p>
          If you have an account with us, we will deliver our written response
          to that account. If you do not have an account with us, we will
          deliver our written response by mail or electronically, at your
          option.
        </p>
        <p>
          Any disclosures we provide will only cover the 12-month period
          preceding the verifiable consumer request's receipt. The response we
          provide will also explain the reasons we cannot comply with a request,
          if applicable. For data portability requests, we will select a format
          to provide your Personal Information that is readily usable and should
          allow you to transmit the information from one entity to another
          entity without hindrance.
        </p>
        <p>
          We do not charge a fee to process or respond to your verifiable
          consumer request unless it is excessive, repetitive, or manifestly
          unfounded. If we determine that the request warrants a fee, we will
          tell you why we made that decision and provide you with a cost
          estimate before completing your request.
        </p>
        <h2>PERSONAL INFORMATION SALES OPT-OUT and OPT-IN RIGHTS</h2>
        <p>
          You have the right to direct us to not sell your personal information
          at any time (the “right to opt-out). To exercise the right to opt-out,
          you (or your authorized representative) may submit a request to us by
          contacting us (see our CONTACT INFORMATION below).
        </p>
        <p>
          Once you make an opt-out request, we will wait at least twelve (12)
          months before asking you to reauthorize Personal Information sales.
          However, you may change your mind and opt back into Personal
          Information sales at any time by visiting our website and sending us a
          message. We will only use Personal Information provided in an opt-out
          request to review and comply with the request.
        </p>
        <h2>CHANGES TO OUR PRIVACY NOTICE</h2>
        <p>
          We reserve the right to amend this privacy notice at our discretion
          and at any time. When we make changes to this privacy notice, we will
          post the updated notice on the website and update the notice's
          effective date. YOUR CONTINUED USE OF OUR WEBSITE FOLLOWING THE
          POSTING OF CHANGES CONSTITUTES YOUR ACCEPTANCE OF SUCH CHANGES.
        </p>
        <h2>CONTACT US</h2>
        <p>
          If you have any questions, comments, or concerns regarding this
          Privacy Notice for California Residents, please contact us at{" "}
          <a href="mailto:support@atm.com">support@ATM.com</a> or ATM.com, Inc.,
          4600 Campus Drive, Suite 107, Newport Beach, CA 92660.
        </p>
      </Container>
    </LayoutWhite>
  )
}

export default PrivacyPolicy
